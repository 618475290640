import {
  PersonBannerSectionIC,
  SectionLayoutVariantsIC
} from '@/lib/interface-types';
import PlusImage from '@/public/plus-icon.svg';
import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import PersonBanner from '../person-banner';

const PersonBannerSection = ({ section }: PersonBannerSectionIC) => {
  const { t } = useTranslation();
  const { title, sectionContentCollection, numberOfLoadedContent, layout } = section;
  const layoutControl: SectionLayoutVariantsIC = {
    '1/3 1/3 1/3': 'grid md:grid-cols-2 gap-12 xl:grid-cols-3 xl:gap-[3.75rem]',
    '1/1': 'grid gap-y-16'
  };

  const [visibleItems, setVisibleItems] = React.useState(
    numberOfLoadedContent === 0 ? 6 : numberOfLoadedContent
  );

  const loadMore = () => {
    setVisibleItems(visibleItems + 4);
  };

  return (
    <section key={uuid()} className="PersonBannerSection w-full mt-12 lg:mt-20">
      {title && <h2 className='mb-6 lg:mb-10'>{title}</h2>}
      <div>
        <section className={`${layoutControl[layout]}`}>
          {sectionContentCollection.items.slice(0, visibleItems).map((item) => (
            <PersonBanner data={item} key={uuid()} sectionbaner />
          ))}
        </section>
        {numberOfLoadedContent !== 0 &&
          visibleItems < sectionContentCollection.items.length && (
            <div
              className="flex items-center cursor-pointer font-bold mt-20"
              onClick={loadMore}
              role="presentation"
            >
              <div className="mr-4 flex">
                <Image src={PlusImage} alt="Load more" width={25} height={25} />
              </div>
              <p className="uppercase mt-[5px]">
                {t('section.show_more')} {section.title}
              </p>
            </div>
          )}
      </div>
    </section>
  );
};

export default PersonBannerSection;
