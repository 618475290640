import ContentfulImage from '@/components/contentful-image';
import { ArticleFeaturedProps } from '@/lib/interface-types';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import ArticleMiddleData from './article-middle-data';
import ArticleMiddleDataMobile from './article-middle-data-mobile';

const ArticleHover = ({ article }: ArticleFeaturedProps) => {
  const { category } = article;
  const [isMobile, setIsMobile] = useState(false);
  const [isTapped, setIsTapped] = useState(false);

  const articleUrl = category
    ? `/${article.category.toLowerCase()}/${article.slug}`
    : `/${article.slug}`;

  useEffect(() => {
    const { userAgent } = window.navigator;
    const isMobileDevice =
      /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    setIsMobile(isMobileDevice);
  }, []);

  const handleTap = () => {
    if (isMobile) {
      setIsTapped(true);
    }
  };

  return (
    <div onClick={handleTap} aria-hidden="true">
      {isMobile ? (
        <>
          <div className="relative w-full h-[330px] min-[1700px]:h-[400px] overflow-hidden">
            {article.featuredVideo?.url ? (
              <div className="player-wrapper">
                <video
                  className="react-player"
                  muted={true}
                  src={article.featuredVideo?.url}
                  controls={false}
                  autoPlay
                  loop
                  width="100%"
                  height="100%"
                  preload="auto"
                  playsInline={true}
                />
              </div>
            ) : (
              article.featuredImage?.url && (
                <ContentfulImage
                  alt={article?.featuredImage?.title}
                  className="ArticleFeaturedImage object-cover"
                  src={article?.featuredImage?.url}
                  fill
                  sizes="99.99vw"
                />
              )
            )}
            <div>
              <ArticleMiddleDataMobile
                article={article}
                articleUrl={articleUrl}
                isTapped={isTapped}
              />
            </div>
          </div>
          {article?.featuredTitle && (
            <h6 className="mt-2 font-sans text-base uppercase">
              {article?.featuredTitle}
            </h6>
          )}
        </>
      ) : (
        <>
          <div className="relative w-full h-[330px] min-[1700px]:h-[400px] overflow-hidden">
            {article.featuredVideo?.url ? (
              <div className="player-wrapper">
                <ReactPlayer
                  muted={true}
                  className="react-player"
                  url={article.featuredVideo?.url}
                  controls={false}
                  playing
                  loop
                  width="100%"
                  height="100%"
                />
              </div>
            ) : (
              article.featuredImage?.url && (
                <ContentfulImage
                  alt={article?.featuredImage?.title}
                  className="ArticleFeaturedImage object-cover"
                  src={article?.featuredImage?.url}
                  fill
                  sizes="99.99vw"
                />
              )
            )}

            <Link href={articleUrl}>
              <ArticleMiddleData article={article} articleUrl={articleUrl} />
            </Link>
          </div>
          <Link href={articleUrl}>
            {article?.featuredTitle && (
              <h6 className="mt-2 font-sans text-base uppercase relative overflow-hidden inline-block">
                {article?.featuredTitle}
                <span className="absolute inset-x-0 bottom-0 h-0.5 bg-black transition-transform duration-500 transform -translate-x-full group-hover:translate-x-0"></span>
              </h6>
            )}
          </Link>
        </>
      )}
    </div>
  );
};

export default ArticleHover;
