const SVGChevron = ({
  fill = '#3CD52E',
  isLeft
}: {
  fill?: string;
  isLeft?: boolean;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={21}
    fill="none"
    style={isLeft ? {transform: 'rotate(180deg)'}: {}}
  >
    <path
      fill={fill}
      d="M1.888 20.519.113 18.744l8.225-8.225L.113 2.294 1.888.519l10 10-10 10Z"
    />
  </svg>
);
export default SVGChevron;
