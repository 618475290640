import { ArticleFeaturedProps } from '@/lib/interface-types';
import ArticleHover from './article-hover';
import ArticleNoHover from './article-no-hover';

const ArticleFeatured = (props: ArticleFeaturedProps) => {
  const { article, classIndex, sectionLayout, hasHoverEffect } = props;
  let spanClass = '';

  if (!article) return <> </>;

  if (classIndex) {
    switch (sectionLayout) {
      case '1/3 1/3 1/3':
        spanClass = 'col-span-1 ';
        break;

      case '1/3 - 2/3':
        if (
          classIndex !== 0 &&
          ((classIndex - 1) % 4 === 0 || (classIndex - 2) % 4 === 0)
        ) {
          spanClass = 'col-span-1 lg:col-span-2';
        }
        break;

      default:
        spanClass = 'col-span-1';
        break;
    }
  }

  return (
    <article className={`ArticleFeatured ${spanClass} secondary-color group`}>
      {hasHoverEffect ? (
        <ArticleHover article={article} />
      ) : (
        <ArticleNoHover article={article} />
      )}
    </article>
  );
};

export default ArticleFeatured;
