import { ArticleFeaturedMobileProps } from '@/lib/interface-types';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import RichText from '../rich-text';

const ArticleMiddleDataMobile = ({
  article,
  articleUrl,
  isTapped
}: ArticleFeaturedMobileProps) => {
  const { industry, services, featuredDescription } = article;
  const { t } = useTranslation();

  const content = isTapped && (
    <Link
      href={`${articleUrl}`}
      className="overlay absolute inset-0 opacity-90 bg-brand-deep-blue hover:bg-opacity-90 p-7 h-full flex justify-between"
    >
       <div className="flex flex-col justify-between">
        <div>
          {industry && (
            <p className="text-brand-green uppercase">{industry}</p>
          )}
          {featuredDescription && (
            <h5 className="text-white m-0">
              <RichText data={featuredDescription} />
            </h5>
          )}
        </div>
        <div>
          {services && (
            <div>
              <p className="mt-3 uppercase text-brand-green">{t('service')}</p>
              {services.map((item: string) => (
                <p className="text-white text-s" key={item}>
                  {item}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </Link>
  ) 

  return content;
};

export default ArticleMiddleDataMobile;
