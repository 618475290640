import { toastUtil } from '@/lib/toast';
import { validateEmail, validateName, validatePhoneNumber } from '@/lib/utils';
import Add from '@/public/icons/add-plus-icon.svg';
import Image from 'next/image';
import Link from 'next/link';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../job-form/spinner';

interface ContactFormProps {
  title?: string;
  subtitle?: string;
  className?: string;
  recipient?: string; //TODO WCC-6: Change to an enum or a list of emails
}

interface FormDataType {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  companyName: string;
  message: string;
  radio: string;
  attachment?: File;
  recipient?: string;
}

interface ErrorObjectIC {
  [key: string]: string;
}
const ContactForm = ({
  title,
  subtitle,
  className,
  recipient
}: ContactFormProps) => {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [errors, setErrors] = useState<ErrorObjectIC>({});
  const [displayError, setDisplayError] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const inputs = ['firstName', 'lastName', 'phone', 'email', 'companyName'];

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisplayError(false);

    const formElement = e.currentTarget;

    const formData = new FormData(e.currentTarget);

    recipient && formData.append('recipient', recipient);
    !selectedFile && formData.delete('attachment');

    const isValidForm = validateForm(Object.fromEntries(formData) as any);

    if (!isValidForm) {
      setDisplayError(true);
      return;
    }

    try {
      setLoading(true);
      const response = await fetch('/api/send-contact-email', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        formElement.reset();
        setSelectedFile(null);
        setErrors({});
        setLoading(false);
      } else {
        setLoading(false);
        toastUtil('error', t('errorMessages.tryAgain'));
      }
    } catch (err: any) {
      Promise.reject(err);
    }
  };

  const validateForm = (formData: FormDataType) => {
    const newErrors: ErrorObjectIC = {
      firstName: validateName(
        formData.firstName,
        t('errorMessages.invalidFirstName')
      ),
      lastname: validateName(
        formData.lastName,
        t('errorMessages.invalidLastName')
      ),
      phone: validatePhoneNumber(
        formData.phone,
        t('errorMessages.invalidPhone')
      ),
      email: validateEmail(formData.email, t('errorMessages.invalidEmail')),
      companyName: !formData.companyName ? t('errorMessages.notEmpty') : '',
      radio: !formData.radio ? '' : t('errorMessages.pleaseConfirm')
    };

    setErrors(newErrors);

    const valid = Object.values(newErrors).every((error) => error === '');
    return valid;
  };

  return (
    <div
      className={`flex flex-col text-base gap-y-10 w-full mt-12 lg:gap-y-20 lg:mt-20  ${
        className ? className : 'my-10'
      }`}
    >
      <h2 className="text-4xl lg:text-[3rem]  mb-2">
        {title ? title : t('contactForm.title')}
      </h2>
      <h5>{subtitle ? subtitle : t('contactForm.subtitle')}</h5>
      <form
        onSubmit={handleFormSubmit}
        className={`w-full bg-white flex flex-col gap-y-10 relative lg:gap-y-20 ${
          className ? className : ''
        }`}
      >
        <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-x-6 gap-y-14">
          {inputs.map((input, index) => {
            return (
              <div className="flex flex-col" key={index}>
                <input
                  type="text"
                  name={input}
                  placeholder={t(`contactForm.${input}`) || ''}
                  className="text-black border-solid border-black border-b pb-1 focus:outline-none placeholder:text-black"
                />
                {errors[input] && (
                  <span className="text-red-500 leading-loose">
                    {errors[input]}
                  </span>
                )}
              </div>
            );
          })}
          <div
            className="flex text-left cursor-pointer justify-self-end items-center gap-x-4"
            onClick={() => {
              const fileInput = document.getElementById(
                'attachment'
              ) as HTMLInputElement | null;
              fileInput?.click();
            }}
            aria-hidden="true"
          >
            <Image
              src={Add}
              alt="Attach file Icon"
              width={24}
              height={24}
              className="cursor-pointer"
            />
            <span className="text-base text-black pt-1 font-bold">
              {selectedFile?.name || t('contactForm.attachFile').toUpperCase()}
            </span>
            <input
              type="file"
              id="attachment"
              name="attachment"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {errors.attachment && (
              <span className="text-red-500 leading-loose">
                {errors.attachment}
              </span>
            )}
          </div>
        </div>
        <textarea
          className="border border-grey-dark py-4 px-11 h-48 focus:outline-none"
          placeholder={`${t('contactForm.typeDescription')}`}
          name="message"
        />
        <div className={`flex items-start gap-4 max-w-[360px]`}>
          <div className="relative flex">
            <input
              type="checkbox"
              id="read"
              className="peer appearance-none w-6 h-6 border-3 border-black checked:border-0 focus:outline-none cursor-pointer z-10 peer"
              name="checkbox"
            />
            <svg
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 hidden peer-checked:block"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinecap="square"
              strokeLinejoin="miter"
            >
              <polyline points="20 6 10 17 4 12"></polyline>
            </svg>
          </div>
          <label
            htmlFor="read"
            className="flex flex-col text-base text-gray-700 font-sans flex"
          >
            <Link className="text-black underline" href="/privacy">
              {t('contactForm.privacyNote')}
            </Link>
            {t('contactForm.personalDataStorage')}
          </label>
        </div>
        <div className="flex flex-col items-start">
          {displayError && (
            <div className="mb-4">
              <span className="text-red-500">
                {t('errorMessages.mandatoryErrorMessage')}
              </span>
            </div>
          )}
          {!loading && (
            <button className="green-button" type="submit">
              <p>{t('jobForm.apply')}</p>
            </button>
          )}
          {loading && <Spinner />}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
