import ContentfulImage from '@/components/contentful-image';
import { SectionIC } from '@/lib/interface-types';
import uuid from 'react-uuid';
import styles from './index.module.css';

interface Props {
  section: SectionIC;
}

interface Image {
  url: string[];
  altText: string[];
}

const Awards = ({ section }: Props) => {
  const {
    title,
    imagesCollection: { items }
  } = section;
  const formatTitle = title.split(' ');

  return (
    <div className="awards mt-20 mb-28 lg:mx-20">
      <div className={`${styles.awardBox} relative`}>
        <div className={`${styles.cutEdge}`}> </div>
        <div className="items-center justify-between min-h-[300px] flex flex-col gap-16 lg:flex-row lg:gap-16 lg:px-10 xl:px-20 xl:gap-20 2xl:gap-40">
          <div className="flex justify-center items-center mt-10 lg:mt-0 lg:flex-col">
            <h2 >{formatTitle[0]}</h2>
            <h2 className="ml-3 lg:ml-0 text-brand-green">{formatTitle[1]}</h2>
          </div>
          <div className="images min-h-[350px] px-10 pb-10 grid grid-cols-2 gap-8 w-full md:grid-cols-3 lg:px-0 lg:pb-0 lg:gap-8 lg:min-h-[200px] xl:gap-12 md:gap-16">
            {items &&
              items.map((img: Image) => (
                <div key={uuid()} className="flex items-center">
                  <div className="relative w-full h-full">
                    <ContentfulImage
                      className="object-contain"
                      src={img.url}
                      alt={img.altText}
                      fill
                      sizes="99.99vw"
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
