import ContentfulImage from '@/components/contentful-image';
import { useOSContext } from '@/context/OSContext';
import { extractFirstSentence, slugify } from '@/lib/utils';
import ArrowForward from '@/public/icons/arrow_forward.svg';
import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { JobItemIC } from '../../lib/interface-types';

interface Props {
  item: JobItemIC;
}

const JobItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const {
    created_at: creationDate,
    id,
    sharing_description,
    locations,
    title: position,
    slug
  } = item || {};

  const OSContext = useOSContext();

  const displayLocation = () => {
    const cities = locations.map((location) => location.city);
    return cities.join(', ');
  };

  const jobIntroduction = extractFirstSentence(sharing_description);

  return (
    <Link
      className="h-full py-[45px] px-[25px]"
      href={`/careers/${slugify(position)}`}
      aria-label={position}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <p className="font-bold uppercase mb-6">{position}</p>
          <p>{jobIntroduction}</p>
          <p className="mt-8">{displayLocation()}</p>
        </div>
        <div className="flex items-center">
          <p className="more-info mt-1">{t('more info')}</p>
          <div
            className={`ml-2 ${
              OSContext?.os === 'Windows' && 'min-[1700px]:mt-[6px]'
            }`}
          >
            <ContentfulImage
              width={14}
              height={14}
              alt="Load more"
              src={ArrowForward}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default JobItem;
