import { ArticleFeaturedProps } from '@/lib/interface-types';
import { useTranslation } from 'react-i18next';
import RichText from '../rich-text';

const ArticleMiddleData = ({ article }: ArticleFeaturedProps) => {
  const { industry, services, featuredDescription } = article;
  const { t } = useTranslation();

  return (
    <div className="overlay absolute inset-0 opacity-0 hover:opacity-90 bg-brand-deep-blue hover:bg-opacity-90 p-7 flex group/item">
      <div className="flex flex-col justify-between transition-all duration-700 delay-100 opacity-0 -translate-y-[-25%] group-hover/item:opacity-100 group-hover/item:translate-y-0">
        <div>
          {industry && (
            <p className="text-brand-green uppercase">{industry}</p>
          )}
          {featuredDescription && (
            <h5 className="text-white m-0">
              <RichText data={featuredDescription} />
            </h5>
          )}
        </div>
        <div>
          {services && (
            <div>
              <p className="mt-3 uppercase text-brand-green">{t('service')}</p>
              {services.map((item: string) => (
                <p className="text-white text-s" key={item}>
                  {item}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleMiddleData;
