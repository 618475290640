import ContentfulImage from '@/components/contentful-image';
import ArticleFeatured from '@/ctf-components/article/article-featured';
import {
  ArticleSectionIC,
  SectionLayoutVariantsIC
} from '@/lib/interface-types';
import PlusImage from '@/public/icons/add-plus-icon.svg';
import PlusGreenImage from '@/public/icons/add-plus-green.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { useOSContext } from '@/context/OSContext';

const ArticleSection = ({ section }: ArticleSectionIC) => {
  const OSContext = useOSContext();
  const { t } = useTranslation();
  const sectionLayoutsOptions: SectionLayoutVariantsIC = {
    '1/3 1/3 1/3':
      'grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 gap-x-12 gap-y-6 xl:gap-x-16 xl:gap-y-6',
    '1/3 - 2/3':
      'grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-x-12 md:gap-y-3 xl:gap-x-16 xl:gap-y-6',
    '1/1': 'grid-cols-12'
  };
  const { items } = section.sectionContentCollection;
  const { hasHoverEffect } = section;
  const [visibleItems, setVisibleItems] = React.useState(() => {
    if (typeof window !== 'undefined') {
      const storedItems = sessionStorage.getItem(
        `visibleItems_${section.title}`
      );
      return storedItems
        ? parseInt(storedItems, 10)
        : section.numberOfLoadedContent === 0
        ? 8
        : section.numberOfLoadedContent;
    }
  });

  useEffect(() => {
    sessionStorage.setItem(
      `visibleItems_${section.title}`,
      String(visibleItems)
    );
  }, [visibleItems, section.title]);

  const loadMore = () => {
    setVisibleItems(visibleItems! + items.length);
  };

  return (
    <>
    <section className="ArticleSection w-full mt-20">
      <h2 className="pb-8">{section.title}</h2>
      <div className={`${sectionLayoutsOptions[section.layout]}`}>
        {items.slice(0, visibleItems).map((article: any, index: any) => (
          <ArticleFeatured
            key={uuid()}
            article={article}
            classIndex={index}
            sectionLayout={section.layout}
            hasHoverEffect={hasHoverEffect}
          />
        ))}
      </div>
      
    </section>
    {section.numberOfLoadedContent !== 0 && visibleItems! < items.length && (
        <div
          className="flex items-center cursor-pointer font-bold mt-10 group"
          onClick={loadMore}
          role="presentation"
        
        >
          <div className="flex items-center">
            <p className="mr-2 mt-2 uppercase group-hover:text-brand-green">
              {t('section.show_more')} {section.title}
            </p>
            <div className={`hidden group-hover:flex ${OSContext?.os === 'Windows' && 'mt-[6px]'}`}>
              <ContentfulImage
                src={PlusGreenImage}
                width={24}
                height={24}
                alt="Load more"
              />
            </div>
            <div className={`group-hover:hidden ${OSContext?.os === 'Windows' && 'mt-[6px]'}`}>
              <ContentfulImage
                src={PlusImage}
                width={24}
                height={24}
                alt="Load more"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ArticleSection;
