import SVGChevron from '@/components/chevron/Chevron';
import ContentfulImage from '@/components/contentful-image';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import { TouchEvent, memo, useEffect, useState } from 'react';

export interface SliderSection {
  section: Slider;
}

export interface Slider {
  __typename: string;
  title: string;
  contentCollection: ContentCollection;
}

export interface ContentCollection {
  items: Item[];
}

export interface Item {
  title: string;
  secondaryTitle: string;
  description: string;
  slideImage: SlideImage;
}

export interface SlideImage {
  url: string;
  width: number;
  height: number;
}

const MemoizedContentfulImage = memo(ContentfulImage);

export const SliderSection = ({ section }: SliderSection) => {
  const isMobile = useIsMobile();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const { contentCollection } = section;
  const { items } = contentCollection;

  useEffect(() => {
    const preloadImages = () => {
      items.forEach((item) => {
        const img = new Image();
        img.src = item.slideImage.url;
      });
    };

    preloadImages();
  }, [items]);

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      nextSlide();
    } else if (touchEndX - touchStartX > 50) {
      prevSlide();
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleCubeClick = (index: number) => {
    setCurrentIndex(index);
  }

  return (
    <div
      className="flex items-start self-stretch gap-8 lg:gap-16 
                slider-negative-magrin py-8 lg:py-20 bg-brand-deep-blue flex-col lg:flex-row my-20"
    >
      <div className="flex items-start justify-end flex-col lg:gap-10 lg:max-w-[40%] slider-text-mobile-padding">
        <h2 className="text-brand-green pb-8 lg:pb-0">
          {items[currentIndex].title}
        </h2>
        <h4 className="text-white m-0 pb-6 lg:p-0">
          {items[currentIndex].secondaryTitle}
        </h4>
        <p className="slider-text text-white">
          {items[currentIndex].description}
        </p>
      </div>
      {isMobile && (
        <div className="flex gap-3 justify-center w-full">
          {items.map((e, i) => (
            <div
              onClick={() => handleCubeClick(i)}
              className={`cube ${i === currentIndex && 'green'}`}
            ></div>
          ))}
        </div>
      )}
      <div className="w-full">
        <div className="w-full h-full">
          <div
            className="h-[300px] w-full lg:h-[400px] relative"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
          >
            {items && (
              <MemoizedContentfulImage
                src={items[currentIndex].slideImage.url}
                alt={`Slide ${currentIndex}`}
                className="object-cover"
                fill
                sizes="99.99vw"
              />
            )}
          </div>
        </div>
        {!isMobile && (
          <div className="mt-5 lg:mt-10 flex justify-between slider-text-mobile-padding">
            <div className="flex gap-6 lg:ml-2">
              <div onClick={prevSlide} className="select-none">
                <SVGChevron isLeft />
              </div>
              <div onClick={nextSlide} className="select-none">
                <SVGChevron />
              </div>
            </div>
            <div>
              <span className="text-brand-green cursor-pointer">
                {currentIndex + 1} /{' '}
              </span>
              <span className="text-white cursor-pointer">{items.length}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
