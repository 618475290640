import Link from 'next/link';
import {useTranslation} from 'react-i18next';
import Icon360 from '../../public/icons/360-icon.svg';
import VirtualShowroom from '../../public/images/virtualshowroom.png';
import ContentfulImage from '../contentful-image';

const VirtualShowRoom = () => {
  const {t} = useTranslation();
  return (
    <div className="grid mt-20 lg:grid-cols-3 gap-8 lg:gap-14">
      <div className="flex flex-col min-w-[300px]">
        <h3 className="lg:max-w-[200px]">{t('home.virtualShowroom')}</h3>
        <div className="hidden mt-6 lg:mt-12 lg:block group">
          <Link
            className="ml-3 green-button"
            target="_blank"
            href="https://www.consulteer.com/virtual-showroom/"
          >
            <p className="group-hover:text-brand-green">
              {t('home.virtualShowroomButton')}
            </p>
          </Link>
        </div>
      </div>
      <div className="relative mt-10 lg:mt-0 h-[330px] 2xl:h-[400px] w-full col-span-2">
        <ContentfulImage
          src={VirtualShowroom}
          alt="Virtual Showroom"
          fill
          sizes="99.99vw"
        />
        <div className="absolute inset-0 flex justify-center items-center">
          <Link
            target="_blank"
            href="https://www.consulteer.com/virtual-showroom/"
          >
            <div className="bg-brand-green w-16 h-16 rounded-full flex justify-center items-center">
              <ContentfulImage src={Icon360} width={40} height={40} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VirtualShowRoom;
