import ArrowUp from '@/public/icons/remove-minus-icon.svg';
import ArrowDown from '@/public/icons/add-plus-icon.svg';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

interface CustomSelectProps {
  items: string[];
  onChange: (selectedItem: string) => void;
  className: string;
}

const SelectBox: React.FC<CustomSelectProps> = ({
  items,
  onChange,
  className
}) => {
  const jobMenu = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const upIcon = ArrowUp;
  const downIcon = ArrowDown;
  const { t } = useTranslation();
  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (
        jobMenu?.current &&
        !jobMenu?.current?.contains(event.target as HTMLElement)
      ) {
        setIsOpen(false);
      }
    }
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: string) => {
    setSelected(item);
    onChange(item);
    setIsOpen(false);
  };

  return (
    <div className={`relative ${className}`}>
      <div
        aria-hidden="true"
        className="flex w-full justify-between lg:justify-end  cursor-pointer focus:outline-none lg:text-base text-sm items-center"
        onClick={toggleDropdown}
        ref={jobMenu}
      >
        <p className='uppercase font-bold text-[14px]'>
          {selected || items[0]}
        </p>
        <Image
          src={isOpen ? upIcon : downIcon}
          alt={isOpen ? 'Arrow up icon' : 'Arrow down icon'}
          className="ml-3 pb-[5px]"
          width={20}
          height={20}
        />
      </div>
      {isOpen && (
        <div className="absolute top-10 w-full justify-start bg-white border-[5px] border-green-500">
          {items.map((item) => (
            <p
              aria-hidden="true"
              key={uuid()}
              className="px-4 py-2 cursor-pointer hover:text-green-500"
              onClick={() => handleItemClick(item)}
            >
              {t(`select_options.${item}`)}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectBox;
